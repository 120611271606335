import Cookies from 'js-cookie'

const TokenKey = 'nauth'
const TenantId = 'tenantId'
const AppId = 'appId'
const SiteId = 'siteId'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function setTokenWithExp(token, expiresIn) {
  if (expiresIn === null || expiresIn === 'undefined' || expiresIn === -1) {
    expiresIn = 1800
  }
  const expireTimes = new Date(new Date().getTime() + parseInt(expiresIn) * 1000)
  return Cookies.set(TokenKey, token, {
    expires: expireTimes
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function setTenant(tenant) {
  return Cookies.set(TenantId, tenant)
}
export function getTenant() {
  var tenantId = Cookies.get(TenantId)
  if (tenantId) { tenantId = Number(tenantId) } else { tenantId = 0 }
  return tenantId
}

export function setApplication(appId) {
  return Cookies.set(AppId, appId)
}

export function getAppId() {
  var appId = Cookies.get(AppId)
  if (appId) { appId = Number(appId) } else { appId = 0 }
  return appId
}

export function removeAppId() {
  return Cookies.remove(AppId)
}
export function setSite(siteId) {
  return Cookies.set(SiteId, siteId)
}

export function getSite() {
  var siteId = Cookies.get(SiteId)
  if (siteId) { siteId = Number(siteId) } else { siteId = 0 }
  return siteId
}

export function removeSiteId() {
  return Cookies.remove(SiteId)
}