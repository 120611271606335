import Vue from 'vue'
import Router from 'vue-router'
// import Home from "../views/Home.vue";

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const defaultRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
        meta: { title: '首页', affix: true }
      },
      {
        path: '/404',
        component: () => import('@/views/error/404'),
        name: 'Error404',
        meta: { title: '401未知页面', noCache: true }
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: defaultRoutes
})

export default router
