import router from './router'
import store from './store'
import { getToken, setTokenWithExp } from '@/utils/auth' // get token from cookie
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message } from 'element-ui'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect']// no redirect whitelist
const defaultSettings = require('./settings.js')

router.beforeEach(async (to, from, next) => {
  // 进度条开始
  NProgress.start()
  // 页面中标题生成 页面中 mate->title + 项目title
  document.title = (to.meta.title || '') + '-' + defaultSettings.title
  // 从配置中获取登录页面的地址，如果没有设置则默认项目的login页面
  const loginPage = (process.env.VUE_APP_SSOURL || '') + '/login'
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      const hasUserInfo = store.getters.userInfo
      // 判断是否有用户信息
      if (hasUserInfo) {
        next()
      } else {
        try {
          // 获取用户信息
          const { id } = await store.dispatch('user/getInfo')
          // 加载用户参数 去除系统加载参数设置，合并到用户信息中统一加载
          // await store.dispatch('user/LoadSysSetting')
          // 动态加载页面路由和生成菜单
          const dynamicRoutes = await store.dispatch('routers/loadRoutes', id)
          // 将生成的路由动态添加到router中
          router.addRoutes(dynamicRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          Message.error(error)
          // 可以在配置中设置登录的地址
          location.href = loginPage + `?redirect=${location.href}`
          NProgress.done()
        }
      }
      NProgress.done()
    }
  } else if (to.query.nauth) {
    // 判断是否传回授权参数信息
    await setTokenWithExp(to.query.nauth, to.query.expireTimes)
    const newQuery = JSON.parse(JSON.stringify(to.query))
    delete newQuery.nauth
    delete newQuery.expireTimes
    next({ ...to, query: newQuery, replace: true })
    NProgress.done()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      location.href = loginPage + '?redirect=' + location.href
      NProgress.done()
    }
  }
})
// 跳转完成，进度条结束
router.afterEach(() => {
  // 完成进度条
  NProgress.done()
})