<template>
  <!-- <li class="menulist" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"> -->
  <li @mouseenter="handleMouseenter" @mouseleave="() => handleMouseleave(false)">
    <div class="menutitle">
      <slot name="title" />
      <!-- <i class="el-submenu__icon-arrow el-icon-arrow-down"></i> -->
    </div>
    <transition name="el-zoom-in-top">
      <ul v-show="opened" ref="menu" class="popper-menu">
        <div class="group-title">采购业务</div>
        <slot />
      </ul>
    </transition>
  </li>
</template>
<script>
import Popper from '@/utils/vue-popper'
import mixin from './menu-mixin'
import Emitter from '@/mixins/emitter'

const poperMixins = {
  props: {
    transformOrigin: {
      type: [Boolean, String],
      default: false
    },
    offset: Popper.props.offset,
    boundariesPadding: Popper.props.boundariesPadding,
    popperOptions: Popper.props.popperOptions
  },
  data: Popper.data,
  methods: Popper.methods,
  beforeDestroy: Popper.beforeDestroy,
  deactivated: Popper.deactivated
}

export default {
  name: 'SubmenuPopper',
  mixins: [mixin, Emitter, poperMixins],
  props: {
    name: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    opened () {
      return this.rootMenu.openedNames.indexOf(this.name) > -1
    }
  },
  watch: {
    opened (val) {
      this.$nextTick(_ => {
        this.updatePopper()
      })
    }
  },
  mounted () {
    this.initPopper()
  },
  methods: {
    handleMouseenter (event, showTimeout = 250) {
      if (!('ActiveXObject' in window) && event.type === 'focus' && !event.relatedTarget) {
        return
      }
      this.dispatch('Submenu', 'mouse-enter-child')
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.rootMenu.updateOpenMenus(this.name)
      }, showTimeout)
    },
    handleMouseleave (deepDispatch = false) {
      this.dispatch('Submenu', 'mouse-leave-child')
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.rootMenu.closeMenu(this.name)
      }, 250)
      if (deepDispatch) {
        if (this.$parent.$options.name === 'Submenu') {
          this.$parent.handleMouseleave(true)
        }
      }
    },
    updatePlacement () {
      this.currentPlacement = 'right-start'
    },
    initPopper () {
      this.referenceElm = this.$el
      this.popperElm = this.$refs.menu
      this.updatePlacement()
    }
  }
}
</script>
