<template>
  <!-- <li class="menulist" @mouseenter="handleMouseenter" @mouseleave="handleMouseleave"> -->
  <li class="productmenulist" @click.stop="handleMenuclick">
    <div class="menutitle" :class="opened ? 'menuopened' : ''">
      <slot name="title" />
      <i class="el-submenu__icon-arrow" :class="opened ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
    </div>
    <el-collapse-transition>
      <ul v-show="opened" class="inline-menu" @click.stop="">
        <slot />
      </ul>
    </el-collapse-transition>
  </li>
</template>
<script>
import mixin from './menu-mixin'
export default {
  name: 'SubmenuInline',
  mixins: [mixin],
  props: {
    name: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    opened () {
      return this.rootMenu.openedNames.indexOf(this.name) > -1
    }
  },
  mounted () {
    if (!this.rootMenu.firstMenuOpened) {
      this.rootMenu.updateOpenMenus(this.name)
      this.rootMenu.firstMenuOpened = true
    }
  },
  methods: {
    handleMenuclick () {
      if (this.opened) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.rootMenu.closeMenu(this.name)
        }, 0)
      } else {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.rootMenu.uniqueOpenMenus(this.name, this.sameLeavelPath)
        }, 0)
      }
      event.stopPropagation()
    }
  }
}
</script>