<template>
  <div class="sidebar-logo-container" :class="{'collapse': collapse}">
    <transition name="sidebarLogoFade">
      <router-link key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: this.$store.getters.userInfo.systemSettingList['systemLogo'] ?? 'http://cdn.nxmsc.com/FvBBiuTPqKXZbKMJ1-RC_PpKW69z'
    }
  }
}
</script>