/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername (str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL (url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail (email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString (str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray (arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
/**
 * 验证用户账号只能为4到16位（字母，数字，下划线，减号）
 * @param rule
 * @param value
 * @param callback
 */
export function validateUserName(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    const reg = /^[a-zA-Z0-9_-]{4,16}$/
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('用户名为4到16位字母/数字/下划线/减号组成'))
    } else {
      callback()
    }
  }
}
/**
 * 验证密码复杂度 中级（同时包含字母和字母）
 * @param rule
 * @param value
 * @param callback
 */
export function validatePassword(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,25}$/
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('密码由数字、字母、特殊字符至少其中两种组合'))
    } else {
      callback()
    }
  }
}
/**
 * 是否合法IP地址
 * @param rule
 * @param value
 * @param callback
 */
export function validateIP(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的IP地址'))
    } else {
      callback()
    }
  }
}

/* 是否手机号码或者固话 */
export function validateEmail(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    if ((!validEmail(value)) && value !== '') {
      callback(new Error('请输入正确的电子邮箱'))
    } else {
      callback()
    }
  }
}

/* 是否手机号码或者固话 */
export function validatePhoneTwo(rule, value, callback) {
  const reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的电话号码或者固话号码'))
    } else {
      callback()
    }
  }
}
/* 是否固话 */
export function validateTelephone(rule, value, callback) {
  const reg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{1,}))?$/

  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的固话（格式：区号+号码+分机号,如010-1234567-213）'))
    } else {
      callback()
    }
  }
}

/* 是否手机号码 */
export function validateMobile(rule, value, callback) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的电话号码'))
    } else {
      callback()
    }
  }
}

/* 是否身份证号码 */
export function validateIdNo(rule, value, callback) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (value === '' || value === undefined || value == null) {
    callback()
  } else {
    if ((!reg.test(value)) && value !== '') {
      callback(new Error('请输入正确的身份证号码'))
    } else {
      callback()
    }
  }
}
/**
 * {m,n}位不为零的正整数
 */
export function validIntegerFixBit(rule, value, callback, from, to) {
  // 以零开头的一位数或不以零开头的+两位小数点
  var regStr = '^[1-9]\\d*$'
  // 验证 from 和 To 是否位数字
  const valid = /^[1-9]\d*$/
  if ((valid.test(from)) && (valid.test(to)) && from > 0 && to > 0) {
    regStr = (to === 1) ? '^[1-9]$' : '^[1-9]([0-9]{' + from + ',' + (to - 1) + '})?$'
  }
  const reg = new RegExp(regStr)
  if (!reg.test(value) && value !== '') {
    callback(new Error('数据有误，为{' + from + ',' + to + '}位不为零的正整数'))
  } else {
    callback()
  }
  return reg.test(value)
}
/**
 * 正数金额
 */
export function validPlusNumberDotWith2Bit(rule, money, callback) {
  // 以零开头的一位数或不以零开头的+两位小数点
  const reg = /^(0|[1-9][0-9]*)(.[0-9]{1,2})?$/
  if (!reg.test(money) && money !== '') {
    callback(new Error('请输入正数(可含两位小数点)。'))
  } else {
    callback()
  }
  return reg.test(money)
}
/**
 * 正负数金额
 */
export function validPlusOrMinusNumberDotWith2Bit(rule, money, callback) {
  // 以零开头的一位数或不以零开头的+两位小数点
  const reg = /^(0|[1-9][0-9]*)(.[0-9]{1,2})?$/
  if (!reg.test(money) && money !== '') {
    callback(new Error('请输入正数或负数(可含两位小数点)'))
  } else {
    callback()
  }
  return reg.test(money)
}