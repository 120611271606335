import request from '@/utils/request'

export function getAllSites() {
  return request({
    url: '/cms/site/all',
    method: 'get'
  })
}
export function getSite(id) {
  return request({
    url: `/cms/site/${id}`,
    method: 'get'
  })
}

export function addSite(data) {
  return request({
    url: '/cms/site',
    method: 'post',
    data
  })
}

export function editSite(data) {
  return request({
    url: '/cms/site',
    method: 'put',
    data
  })
}

export function deleteSite(ids) {
  return request({
    url: '/cms/site',
    method: 'delete',
    data: ids
  })
}
