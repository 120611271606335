<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <Menu :background-color="variables.menuBg">
        <Sidebar-item v-for="item in menusList" :key="item.id" :item="item" :url="item.path" />
      </Menu>
    </el-scrollbar>
  </div>
</template>
<script>
import Logo from './Logo'
import { mapGetters } from 'vuex'
import Menu from '@/components/CustomerMenu/menu.vue'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { Logo, Menu, SidebarItem },
  data() {
    return {
      menusList: [],
      userId: 0
    }
  },
  computed: {
    variables() {
      return variables
    },
    showLogo() {
      return true
    },
    isCollapse() {
      return false
    },
    ...mapGetters([
      'userInfo',
      'menus'
    ])
  },
  mounted() {
    this.userId = this.userInfo.id
    this.menusList = this.menus
  }
  // methods: {
  //   loadMenu() {
  //     console.log(this.$store.state)
  //     getUserMenu(this.userId).then(res => {
  //       this.menus = res.data
  //     })
  //   }
  // }
}
</script>