import request from '@/utils/request'

export function getAllMenus() {
  return request({
    url: '/sys/menu/all',
    method: 'get'
  })
}

export function addMenu(data) {
  return request({
    url: '/sys/menu/add',
    method: 'post',
    data
  })
}

export function getUserMenu(userid) {
  return request({
    url: '/sys/menu',
    method: 'get',
    params: { userid }
  })
}

export function editMenu(data) {
  return request({
    url: '/sys/menu/edit',
    method: 'post',
    data
  })
}

export function deleteMenu(id) {
  return request({
    url: `/sys/menu/${id}`,
    method: 'delete'
  })
}

export function getMenuTreeWithActionsByMenuId(id) {
  return request({
    url: `/sys/action/menutree/${id}`,
    method: 'get'
  })
}
export function getActionsByMenuId(id) {
  return request({
    url: `/sys/action/list/${id}`,
    method: 'get'
  })
}
export function getUserAuthedActions(id) {
  return request({
    url: `/sys/action/userAuthed/${id}`,
    method: 'get'
  })
}

export function addAction(data) {
  return request({
    url: '/sys/action/save',
    method: 'post',
    data
  })
}

export function editAction(data) {
  return request({
    url: '/sys/action',
    method: 'put',
    data
  })
}

export function deleteActions(ids) {
  return request({
    url: '/sys/action',
    method: 'delete',
    data: ids
  })
}

export function getColumnsByMenuId(id) {
  return request({
    url: `/sys/column/list/${id}`,
    method: 'get'
  })
}

export function addColumn(data) {
  return request({
    url: '/sys/column/save',
    method: 'post',
    data
  })
}

export function editColumn(data) {
  return request({
    url: '/sys/column',
    method: 'put',
    data
  })
}