<template>
  <ul :class="classes" :style="styles">
    <slot />
  </ul>
</template>
<script>
// const poperMixins
export default {
  name: 'Menu',
  provide() {
    return {
      rootMenu: this
    }
  },
  props: {
    activeName: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    openNames: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      currentActiveMenu: this.activeName,
      openedNames: []
    }
  },
  computed: {
    classes() {
      return [
        'cs-menu'
      ]
    },
    styles() {
      const style = {}
      style.backgroundColor = this.backgroundColor
      return style
    }
  },
  methods: {
    updateOpenMenus(name) {
      const openedNames = this.openedNames
      if (openedNames.indexOf(name) !== -1) return
      this.openedNames.push(name)
    },
    uniqueOpenMenus(name, indexPath) {
      const openedNames = this.openedNames
      if (openedNames.indexOf(name) !== -1) return
      this.openedNames = openedNames.filter(index => {
        return indexPath.indexOf(name) !== -1
      })
      this.openedNames.push(name)
    },
    closeMenu(name) {
      const i = this.openedNames.indexOf(name)
      if (i !== -1) {
        this.openedNames.splice(i, 1)
      }
    }
  }
}
</script>
<style scoped>
.cs-menu {
  padding: 0px;
}
</style>