<template>
  <div v-if="!item.isHidden">
    <template v-if="!item.children || item.children.length === 0">
      <app-link :to="resolvePath(item)">
        <mitem>
          <div class="menutitle">
            <item :icon="item.icon" :title="item.name" />
          </div>
        </mitem>
      </app-link>
    </template>
    <SubmenuInline v-else-if="item.isproduct" :name="item.id">
      <mitem slot="title">
        <item :icon="item.icon" :title="item.name" />
      </mitem>
      <SidebarItem
        v-for="subitem in item.children"
        :key="subitem.id"
        :item="subitem"
        :url="subitem.path"
      />
    </SubmenuInline>
    <SubmenuPopper v-else :name="item.id">
      <app-link slot="title" :to="resolvePath(item)">
        <mitem>
          <item :icon="item.icon" :title="item.name" />
        </mitem>
      </app-link>
      <SidebarItem
        v-for="subitem in item.children"
        :key="subitem.id"
        :item="subitem"
        :url="subitem.path"
      />
    </SubmenuPopper>
  </div>
</template>
<script>
import SubmenuPopper from '@/components/CustomerMenu/submenupopper.vue'
import SubmenuInline from '@/components/CustomerMenu/submenuinline.vue'
import mitem from '@/components/CustomerMenu/menuitem.vue'
import AppLink from './Link'
import Item from './Item'
export default {
  name: 'SidebarItem',
  components: { SubmenuPopper, SubmenuInline, mitem, AppLink, Item },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    resolvePath(route) {
      // 使用iframe内嵌
      if (route.component && route.component === 'IframeLayout') {
        return '/' + route.componentName
      } else {
        return route.path
      }
    }
  }
}
</script>