import { getUserMenu } from '@/api/sys/menus'
import { defaultRoutes } from '@/router'
export function formatDynamicRoutes(routers) {
  const formatRoutes = []
  routers.forEach(route => {
    let { path, component, componentName, children } = route
    let subFixIndex = null
    // 如果component以index结尾，去除尾部的index
    if (component && component.substring(component.lastIndexOf('/') + 1).toLowerCase() === 'index') {
      // 去除尾部index
      subFixIndex = component.substring(component.lastIndexOf('/'), -1)
    }
    // 如果component有值
    if (component) {
      // 如果path已经有'/'先去除
      path = path.indexOf('/') === 0 ? path.substring(1, path.length) : path
      // 如果后台设置了path路径则使用path路径，如果没有设置则根据component生成路径（component如果以index结尾则去除）
      path = `/${path || (subFixIndex || component)}`
      // 如果后台设置了componentName路径则使用设置的名称，如果没有设置则将component中'/'替换成'_'后生成name（component如果以index结尾则去除）
      componentName = componentName || (subFixIndex || component).replace(/\//g, '_')
      // 如果component以'/'结尾,则自动自动转到'index'页面
      component = component.lastIndexOf('/') + 1 === component.length ? component + 'index' : component
      // 如果是url则地址栏不使用http的URL全路径
      if (path.indexOf('http') > -1 || path.indexOf('https') > -1) {
        path = '/' + componentName
      }
    }
    // 如果children有值，则调用递归生成children
    if (children && children.length) {
      children = formatDynamicRoutes(children)
    }

    const formatRoute = {
      path: path,
      name: componentName,
      children: children,
      component(resolve) {
        if (route.component === 'Layout') {
          require([`@/layout/index.vue`], resolve)
        } else if (route.component === 'IframeLayout') {
          require([`@/layout/iframe.vue`], resolve)
        } else {
          require([`@/views/${component}.vue`], resolve)
        }
      },
      meta: {
        routeId: route.id,
        title: route.name,
        icon: route.icon,
        url: route.path,
        dataUrl: route.dataUrl
      },
      hidden: route.hidden || false
    }

    formatRoutes.push(formatRoute)
  })
  return formatRoutes
}
const state = {
  routes: [],
  menus: []
}

const mutations = {
  SET_MENUBAR: (state, menus) => {
    state.menus = menus
  },
  SET_ROUTES: (state, routes) => {
    state.routes = defaultRoutes.concat(routes)
  }
}

const actions = {
  loadRoutes({ commit }, userid) {
    return new Promise((resolve, reject) => {
      let dynamicRouters
      getUserMenu(userid).then(response => {
        if (response.code !== '000000') {
          reject(response.msg)
        }
        commit('SET_MENUBAR', response.data)
        // 将后台获取的menu格式化成vue中的Router格式
        dynamicRouters = formatDynamicRoutes(response.data)
        // 在所有路由最后添加404页面来拦截所有未找到页面
        dynamicRouters.push({ path: '*', redirect: '/404', hidden: true })
        commit('SET_ROUTES', dynamicRouters)
        resolve(dynamicRouters)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}