import { getInfoInTenant } from '@/api/sys/users'
import { getAppSettings } from '@/api/sys/settings'
import { getToken, setTenant, setApplication, removeAppId, removeToken, setSite, getSite, removeSiteId } from '@/utils/auth'

const state = {
  // 用户认证信息
  token: getToken(),
  // 用户信息
  userInfo: '',
  // 系统配置
  settings: null
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, user) => {
    state.userInfo = user
  },
  SET_USER_TENANT: (state, tenantId) => {
    state.userInfo.tenantId = tenantId
  },
  SET_USER_APP: (state, appId) => {
    state.userInfo.appId = appId
  },
  SET_USER_SITE: (state, siteList) => {
    var cookieSiteId = getSite()
    var currentSite = _.find(siteList, item => item.selected)
    if (currentSite === undefined) {
      if (!cookieSiteId) {
        currentSite = siteList[0]
      } else {
        currentSite = _.find(siteList, item => item.id === cookieSiteId)
      }
    }
    if (currentSite) {
      state.userInfo.siteId = currentSite.id
      setSite(currentSite.id)
      if (!siteList.find(item => item.selected)) {
        // 如果cookie中有值使用cookied的，如果没有默认选中第一条
        if (cookieSiteId) {
          siteList.find(item => item.id === cookieSiteId).selected = true
        } else {
          // 设置默认第一条记录选中
          siteList[0].selected = true
        }
      }
    }
    state.userInfo.userSiteList = siteList
  },
  SET_SYSTEM_SETTINGS: (state, settings) => {
    state.settings = settings
  }
}

const actions = {
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfoInTenant().then(response => {
        const { data } = response
        if (response.code !== '000000') {
          reject(response.msg)
        }
        // const { name, avatar, nickName, roles, tenantId } = data
        commit('SET_USER', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout({ commit, state }) {
    const loginPage = (process.env.VUE_APP_SSOURL + '/logout' || '/logout')
    commit('SET_TOKEN', '')
    commit('SET_USER', [])
    removeToken()
    removeSiteId()
    removeAppId()

    location.href = loginPage + '?redirect=' + location.href
  },
  async changeTenantId({ commit }, tanantId) {
    setTenant(tanantId)
    commit('SET_USER_TENANT', tanantId)
  },
  async changeApplication({ commit }, appId) {
    setApplication(appId)
    commit('SET_USER_APP', appId)
  },
  async setSiteInfo({ commit }, siteList) {
    commit('SET_USER_SITE', siteList)
  },
  async LoadSysSetting({ commit }) {
    await getAppSettings().then(res => {
      const setting = {}
      if (res.ok) {
        res.data.forEach(element => {
          setting[element.paramKey] = element.paramValue
        })
      }
      commit('SET_SYSTEM_SETTINGS', setting)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}