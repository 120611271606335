import axios from 'axios'
import { getToken, getTenant, removeToken, getAppId } from './auth'
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
// const defaultSettings = require('@/settings.js')
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
  timeout: 25000 // 请求时间
})
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['nauth'] = getToken()
    }
    if (getAppId()) {
      config.headers['appId'] = getAppId()
    }
    // 设置租户tenantId
    if (getTenant()) {
      config.headers['tenantId'] = getTenant()
    } else if (store.getters.userInfo && store.getters.userInfo.tenantId != null) {
      config.headers['tenantId'] = store.getters.userInfo.tenantId
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200 && res.code !== '000000') {
      Message({
        message: res.msg || '未知错误！',
        type: 'error',
        duration: 5 * 1000
      })
      if (res.code === 401) {
        if (getToken()) {
          MessageBox.alert('无权限访问该资源！')
        }
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // 当授权码无效时进行重新登录
    if (error.request.status === 401) {
      Message({
        message: '请求授权码无效，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken()
      return Promise.reject(error)
    } else if (error.request.status === 503) {
      Message({
        message: '服务不可用，请稍后再试！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service