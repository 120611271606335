import request from '@/utils/request'

export function getUsers(query) {
  return request({
    url: '/sys/user',
    method: 'get',
    params: query
  })
}

export function getInfoInTenant() {
  return request({
    url: '/sys/user/userParams',
    method: 'get'
  })
}
export function getUserTenants() {
  return request({
    url: '/sys/user/tenants',
    method: 'get'
  })
}
export function addUser(data) {
  return request({
    url: '/sys/user/add',
    method: 'post',
    data
  })
}

export function editUser(data) {
  return request({
    url: '/sys/user',
    method: 'put',
    data
  })
}

export function deleteUser(ids) {
  return request({
    url: '/sys/user',
    method: 'delete',
    data: ids
  })
}

export function disableUser(id, status) {
  return request({
    url: `/sys/user/${id}`,
    method: 'put',
    params: { status }
  })
}