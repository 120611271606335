<template>
  <div :class="showPopper?'shadomOpen':''">
    <div ref="targetEle" :class="showPopper?'openPopper':''">
      <div class="title"><slot /></div>
      <i v-if="showLeftArrow ||showPopper" :class="(showPopper?'el-icon-arrow-up':'el-icon-arrow-down')+ ' el-icon--right'" />
    </div>
    <transition name="el-zoom-in-top" @after-leave="handleAfterLeave" @after-enter="handleAfterEnter">
      <div
        v-show="showPopper"
        :id="popperId"
        ref="popper"
        class="el-popover popper"
        :class="popperClass"
        :style="{width:width+'px'}"
      >
        <slot name="dropdown" />
      </div>
    </transition>
  </div>
</template>
<script>
import Emitter from '@/mixins/emitter'
import { on, off, addClass, removeClass } from '@/utils/dom'
import Popper from '@/utils/vue-popper'
import { generateId } from '@/utils/util'
const elementResizeDetectorMaker = require('element-resize-detector')
export default {
  name: 'NxDrodown',
  mixins: [Popper, Emitter],
  props: {
    placement: {
      type: String,
      default: 'bottom-start'
    },
    poperArrowVisiable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: 'click',
      validator: value => ['click', 'focus', 'hover', 'manual'].indexOf(value) > -1
    },
    showTimeout: {
      type: Number,
      default: 250
    },
    hideTimeout: {
      type: Number,
      default: 150
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    tabindex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      poperArrow: false,
      showLeftArrow: false,
      targetEle: null,
      width: 180
    }
  },
  computed: {
    popperId() {
      return `el-popover-${generateId()}`
    }
  },
  watch: {
    showPopper(val) {
      if (this.disabled) {
        return
      }
      val ? this.$emit('show') : this.$emit('hide')
    }
  },
  mounted() {
    this.initPopper()
  },
  destroyed() {
    const reference = this.targetEle

    off(reference, 'click', this.doToggle)
    off(reference, 'mouseup', this.doClose)
    off(reference, 'mousedown', this.doShow)
    off(reference, 'focusin', this.doShow)
    off(reference, 'focusout', this.doClose)
    off(reference, 'mousedown', this.doShow)
    off(reference, 'mouseup', this.doClose)
    off(reference, 'mouseleave', this.handleMouseLeave)
    off(reference, 'mouseenter', this.handleMouseEnter)
    off(document, 'click', this.handleDocumentClick)
  },
  methods: {
    initPopper() {
      const targetEle = this.referenceElm = this.targetEle || this.$refs.targetEle
      const popperEle = this.popperEle || this.$refs.popper

      if (targetEle) {
        addClass(targetEle, 'el-popover__reference')
        targetEle.setAttribute('aria-describedby', this.popperId)
        targetEle.setAttribute('tabindex', this.tabindex) // tab序列
        popperEle.setAttribute('tabindex', 0)
        const erd = elementResizeDetectorMaker()
        const that = this
        erd.listenTo(targetEle, reszie => {
          that.$nextTick(function () {
            // 弹出框宽度
            that.width = reszie.clientWidth
          })
        })
        if (this.trigger !== 'click') {
          on(targetEle, 'focusin', () => {
            this.handleFocus()
            const instance = targetEle.__vue__
            if (instance && typeof instance.focus === 'function') {
              instance.focus()
            }
          })
          on(popperEle, 'focusin', this.handleFocus)
          on(targetEle, 'focusout', this.handleBlur)
          on(popperEle, 'focusout', this.handleBlur)
        }
        on(targetEle, 'keydown', this.handleKeydown)
        on(targetEle, 'click', this.handleClick)
      }
      if (this.trigger === 'click') {
        on(targetEle, 'click', this.doToggle)
        on(document, 'click', this.handleDocumentClick)
        on(targetEle, 'mouseenter', () => { this.showLeftArrow = true })
        on(targetEle, 'mouseleave', () => { this.showLeftArrow = false })
      } else if (this.trigger === 'hover') {
        on(targetEle, 'mouseenter', this.handleMouseEnter)
        on(popperEle, 'mouseenter', this.handleMouseEnter)
        on(targetEle, 'mouseleave', this.handleMouseLeave)
        on(popperEle, 'mouseleave', this.handleMouseLeave)
      } else if (this.trigger === 'focus') {
        if (this.tabindex < 0) {
          console.warn('[DowpDown Warn][Popover]a negative taindex means that the element cannot be focused by tab key')
        }
        if (targetEle.querySelector('input, textarea')) {
          on(targetEle, 'focusin', this.doShow)
          on(targetEle, 'focusout', this.doClose)
        } else {
          on(targetEle, 'mousedown', this.doShow)
          on(targetEle, 'mouseup', this.doClose)
        }
      }
    },
    doToggle() {
      this.showPopper = !this.showPopper
    },
    doShow() {
      this.showPopper = true
    },
    doClose() {
      this.showPopper = false
    },
    handleFocus() {
      addClass(this.referenceElm, 'focusing')
      if (this.trigger === 'click' || this.trigger === 'focus') this.showPopper = true
    },
    handleClick() {
      removeClass(this.referenceElm, 'focusing')
    },
    handleBlur() {
      removeClass(this.referenceElm, 'focusing')
      if (this.trigger === 'click' || this.trigger === 'focus') this.showPopper = false
    },
    handleMouseEnter() {
      clearTimeout(this._timer)
      if (this.openDelay) {
        this._timer = setTimeout(() => {
          this.showPopper = true
        }, this.openDelay)
      } else {
        this.showPopper = true
      }
      this.showLeftArrow = true
    },
    handleKeydown(ev) {
      if (ev.keyCode === 27 && this.trigger !== 'manual') { // esc
        this.doClose()
      }
    },
    handleMouseLeave() {
      clearTimeout(this._timer)
      if (this.closeDelay) {
        this._timer = setTimeout(() => {
          this.showPopper = false
        }, this.closeDelay)
      } else {
        this.showPopper = false
      }
      this.showLeftArrow = false
    },
    handleAfterEnter() {
      this.$emit('after-enter')
    },
    handleAfterLeave() {
      this.$emit('after-leave')
      this.doDestroy()
    },
    cleanup() {
      if (this.openDelay || this.closeDelay) {
        clearTimeout(this._timer)
      }
    },
    handleDocumentClick(e) {
      let reference = this.targetEle || this.$slots.default[0].elm
      const popper = this.popper || this.$refs.popper

      if (!reference && this.$refs.wrapper.children) {
        reference = this.referenceElm = this.$refs.wrapper.children[0]
      }
      if (!this.$el ||
        !reference ||
        this.$el.contains(e.target) ||
        reference.contains(e.target) ||
        !popper ||
        popper.contains(e.target)) return
      this.showPopper = false
    }
  }
}
</script>
<style lang="scss">
.popper{
  border-radius: 0px !important;
  margin-top: 0px !important;
  border-top: none !important;
  -webkit-box-shadow: none !important;
  box-shadow:none !important;
  padding: 12px 0 !important;
}
.title{
  display:inline-block;
  padding-left: 15px;
}
.openPopper{
  border: 1px solid #e6ebf5;
  z-index: 2000;
  text-align: justify;
  word-break: break-all;
  border-bottom: none;
  color: #0096ff;
}
.shadomOpen{
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.overflowHidden{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.selectitem{
  color: #46a6ff !important;
  cursor: default !important;
  font-weight: bold;
}
</style>