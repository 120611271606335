import request from '@/utils/request'

export function getSettings(query) {
  return request({
    url: '/sys/settings',
    method: 'get',
    params: query
  })
}
export function getAppSettingsByParentId(parentId) {
  return request({
    url: `/sys/settings/app/${parentId}`,
    method: 'get'
  })
}
export function getAppSettings() {
  return request({
    url: '/sys/settings/tenantApp',
    method: 'get'
  })
}
export function getAllSettings() {
  return request({
    url: '/sys/settings/all',
    method: 'get'
  })
}

export function getEditionRelationSettings(editionId) {
  return request({
    url: `/sys/applicationeditionssettings/edition/${editionId}`,
    method: 'get'
  })
}
export function addSetting(data) {
  return request({
    url: '/sys/settings',
    method: 'post',
    data
  })
}

export function editSetting(data) {
  return request({
    url: '/sys/settings',
    method: 'put',
    data
  })
}

export function deleteSettings(ids) {
  return request({
    url: '/sys/settings',
    method: 'delete',
    data: ids
  })
}

export function saveTenantSetting(data) {
  return request({
    url: '/sys/settings/TenantSave',
    method: 'post',
    data
  })
}

export function saveEditionRelation(editionId, data) {
  return request({
    url: `/sys/applicationeditionssettings/save/${editionId}`,
    method: 'post',
    data
  })
}