export default {
  inject: ['rootMenu'],
  data() {
    return {
      firstMenuOpened: false
    }
  },
  computed: {
    indexPath() {
      const path = [this.name]
      let parent = this.$parent
      while (parent.$options.name !== 'Menu') {
        if (parent.name) {
          path.unshift(parent.name)
        }
        parent = parent.$parent
      }
      return path
    },
    sameLeavelPath() {
      const sameName = this.name
      const parent = this.$parent
      return parent.$children.filter((item) => {
        return item.$options.name === sameName
      })
    }
  }
}