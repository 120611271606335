<template>
  <li>
    <div>
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  name: 'MenuItem'
  // computed:{
  //   activedmenu(){

  //   }
  // }
}
</script>