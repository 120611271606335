import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './styles/element-variables.scss'
import './directive/dialogdrag'
import _ from 'lodash'

import './icons' // icon
import './permission' // permission control

Vue.config.productionTip = false

import '@/styles/index.scss' // global css
// import 'view-design/dist/styles/iview.css';

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})
// 定义lodash工具为 _ (下划线)
Vue.prototype._ = _
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
