<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render (h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      if (icon.includes('icon-')) {
        vnodes.push(<i class={[icon, 'icon-menu']} />)
      } else {
        vnodes.push(<svg-icon icon-class={icon} class-name='icon-menu' />)
      }
    }

    if (title) {
      vnodes.push(<span>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.icon-menu {
  color: currentColor;
  font-size: 18px;
  width: 1em;
  height: 1em;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  line-height: 18px;
}
</style>
