<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <el-scrollbar class="scroll-main" wrap-class="scrollbar-wrap">
        <keep-alive :include="cachedViews">
          <router-view :key="key" />
        </keep-alive>
      </el-scrollbar>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews () {
      return this.$store.state.tagsView.cachedViews
    },
    key () {
      return this.$route.path
    }
  }
}
</script>