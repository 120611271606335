module.exports = {
  title: '创星云',
  // loginUrl: 'http://192.168.199.3:9001/login',
  // ssoUrl: 'http://192.168.199.3:9001'

  // loginUrl: 'http://sso.n.com/login',
  // ssoUrl: 'http://sso.n.com'
  // loginUrl: 'http://192.168.3.100:9001/login',
  // ssoUrl: 'http://192.168.3.100:9001'

  loginUrl: 'https://sso.nxmsc.com/login',
  ssoUrl: 'https://sso.nxmsc.com'
}